import React from 'react';
import Card from '@/components/atoms/Card';
import { MdInfo } from 'react-icons/md';

const OrderSummary = ({ data }: any) => {
	return (
		<Card bordered={false} bodyStyle={{}} size='small'>
			<div className='mx-5' style={{ borderBottom: '1px solid #F1F2F4' }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%'
					}}>
					<p style={{ margin: 0 }}>Subtotal (MRP)</p>
					<p style={{ margin: 0, textAlign: 'right' }}>৳{data?.po_subtotal}</p>
				</div>

				{data?.deductions && typeof data.deductions === 'object' && Object.entries(data.deductions).length > 0 ? (
					Object.entries(data.deductions as any).map(([type, deduction], i) => {
						// @ts-expect-error to ignore TypeScript error for the following line
						if (!deduction || !deduction?.amount || !deduction?.text) {
							return null;
						}
						const { amount, text }: any = deduction || {};
						if (typeof amount !== 'number' || !text) return null;

						return (
							<div
								key={i} // Add key for list rendering
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									marginTop: '5px',
									width: '100%'
								}}>
								<p className='text-capitalize' style={{ margin: 0 }}>
									{text}
								</p>
								<p style={{ margin: 0, color: '#FD6A6A', textAlign: 'right' }}>-৳{amount}</p>
							</div>
						);
					})
				) : (
					<p></p>
				)}
				{data?.po_user_bonus_used > 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%'
						}}>
						<p style={{ margin: 0 }}>Bonus Applied</p>
						<p style={{ margin: 0, textAlign: 'right', color: '#FD6A6A' }}>-৳{data?.po_user_bonus_used}</p>
					</div>
				)}
				{data?.po_user_cash_used > 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%'
						}}>
						<p style={{ margin: 0 }}>Arogga Cash Applied</p>
						<p style={{ margin: 0, textAlign: 'right', color: '#FD6A6A' }}>-৳{data?.po_user_cash_used}</p>
					</div>
				)}
				{data?.de_options && typeof data.de_options === 'object' && Object.keys(data.de_options).length > 0 ? (
					Object.entries(data.de_options).map(([key, item], i) => {
						if (!item) return null; // Handle null or undefined item
						const { amount }: any = item || {}; // Safely destructure item
						return (
							<div
								key={i} // Add a key for list rendering
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									marginTop: '5px',
									marginBottom: '10px',
									width: '100%'
								}}>
								<p className='text-capitalize' style={{ margin: 0 }}>
									{key === 'regular' ? 'Regular Delivery' : 'Express Delivery'}
								</p>
								<p style={{ margin: 0, textAlign: 'right' }}>
									{amount === 0 ? <span className='text-primary'>Free</span> : <span>+৳{amount}</span>}
								</p>
							</div>
						);
					})
				) : (
					<p></p>
				)}
			</div>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '10px',
						width: '100%',
						marginTop: '10px'
					}}>
					<p className='mx-5' style={{ fontSize: '16px', fontWeight: 600 }}>
						Amount Payable:
					</p>
					<p className='mx-5' style={{ fontSize: '16px', fontWeight: 600, textAlign: 'right', color: '#0E7673' }}>
						৳{data?.po_payable_total}
					</p>
				</div>
				{data?.de_options && typeof data.de_options === 'object' && Object.keys(data.de_options).length > 0 ? (
					Object.entries(data.de_options).map(([key, item], i) => {
						// @ts-expect-error to ignore TypeScript error for the following line
						if (!item || !item?.info) return null;
						const { info }: any = item || {};
						return (
							<>
								{info && (
									<p className='bg-info p-6 rounded-4 d-flex gap-6 items-center' style={{ width: '80%' }}>
										<MdInfo
											style={{
												color: '#2970FF'
											}}
										/>
										<span
											className='text-grey900 
											fw-500 text-10
											'>
											{info}
										</span>
									</p>
								)}
							</>
						);
					})
				) : (
					<p></p> // Fallback message if de_options is unavailable or empty
				)}
			</div>
		</Card>
	);
};

export default OrderSummary;
