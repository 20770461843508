import { MdArrowDropDown } from 'react-icons/md';
import styles from './Dropdown.module.css';

const Dropdown = ({ variant, placeHolder, value, onClick, fullWidth, size, iconRight, children }) => {
	return (
		<div className={`${styles.dropdown_container} relative`}>
			<div
				onClick={onClick}
				className={`${styles.testCard_patient_select} ${placeHolder === '1 Patient' ? styles[`patient_1`] : ''}  ${
					size ? styles[size] : ''
				} ${fullWidth ? styles.fullWidth : ''} ${variant ? styles[variant] : ''}`}>
				<p
					className={`${size === 'xs' ? 'ltbody_sm__regular' : 'ltbody_lg__regular'} ${
						value ? 'primaryBase' : 'primaryBase'
					}`}>
					{value || placeHolder || 'Select'}
				</p>
				{iconRight ? iconRight : <MdArrowDropDown size={20} />}
			</div>
			{children}
		</div>
	);
};

export default Dropdown;
