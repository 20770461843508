import React from 'react';
import styles from './Checkbox.module.css';

const Checkbox = ({ checked, onChange, ...rest }) => {
	return (
		<div className={styles.checkboxOverride}>
			<input
				{...rest}
				className={styles.checkbox}
				checked={checked}
				onChange={onChange}
				type='checkbox'
				id='checkboxInputOverride'
			/>
			<label htmlFor='checkboxInputOverride'></label>
		</div>
	);
};

export default Checkbox;
