import { BiSolidOffer } from 'react-icons/bi';

import styles from './CouponCodeInput.module.css';

const CouponCodeInput = ({
	couponCode,
	setCouponCode,
	onClickApply,
	onClickReset,
	isCouponCodeApplied,
	applyButtonText = 'Apply'
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.iconContainer}>
				<BiSolidOffer size={20} className={styles.icon} />
			</div>
			<input
				className={styles.input}
				type='text'
				placeholder='Enter Coupon Code'
				value={couponCode}
				disabled={isCouponCodeApplied}
				onChange={(e) => setCouponCode(e.target.value)}
			/>
			<button
				className={styles.button}
				disabled={applyButtonText === 'Apply' && !couponCode}
				style={{
					backgroundColor: applyButtonText === 'Remove' && 'red'
				}}
				onClick={applyButtonText === 'Apply' ? () => onClickApply(couponCode) : onClickReset}>
				{applyButtonText}
			</button>
		</div>
	);
};

export default CouponCodeInput;
