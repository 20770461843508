'use client';

import useCart from '@/contexts/CartProvider';
import useStickyCartContext from '@/contexts/StickyCartProvider';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';
import { MdOutlineShoppingCart } from 'react-icons/md';
interface Props {
	className?: string;
}
const loadedCallback = null;
let loaded = false;
const Odometer = dynamic(
	async () => {
		const mod = await import('react-odometerjs');
		loaded = true;
		if (loadedCallback != null) {
			loadedCallback();
		}
		return mod;
	},
	{
		ssr: false,
		loading: () => <span>0</span>
	}
);
const MiniCart = ({ className }: Props) => {
	const { toggleDrawer, getCart } = useCart();
	const { stickyCartDetails } = useStickyCartContext();

	return (
		<>
			<MdOutlineShoppingCart
				size={26}
				onClick={() => {
					toggleDrawer();
					setTimeout(() => getCart(), 1000);
				}}
			/>
			<div
				onClick={() => {
					toggleDrawer();
					setTimeout(() => getCart(), 1000);
				}}
				className={className}>
				<Suspense fallback={<span>0</span>}>
					<Odometer value={stickyCartDetails?.count || 0} format='(ddd)' className='odometer' />
				</Suspense>
			</div>
		</>
	);
};

export default MiniCart;
