import { IC_COLD, IC_EXPRESS, IC_REGULAR, IC_RX } from '@/assets/icons';
import SelectCart from '@/components/atoms/SelectCart';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import {
	checkDeliveryAndStock,
	formatPrice,
	generateProductLink,
	generateQuantityOptions,
	getProductImage,
	renderLabel
} from '@/services/utils/helper';
import { imageLoader } from '@/services/utils/imageLoader';
import { shimmer, toBase64 } from '@/services/utils/LoadingImage';
import { map } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import styles from './CartItems.module.scss';

interface CartItemProps {
	key: any;
	item: any;
	userType?: any;
	isDhakaCity?: any;
	cartAction?: any;
	options?: any;
	removeIcon?: any;
	cartsUcAddress?: any;
	currentPv?: any;
	showItemsRight?: any;
	qty_decrease_message?: any;
	ButtonComponent?: any;
	showNotify?: any;
	isUnavailable?: any;
}
const CartItem = ({
	key,
	item,
	userType,
	isDhakaCity,
	cartAction,
	options,
	removeIcon,
	cartsUcAddress,
	currentPv,
	showItemsRight = true,
	qty_decrease_message,
	ButtonComponent = null,
	showNotify = false,
	isUnavailable = false
}: CartItemProps) => {
	const deleveryType = !!item?.stock_status ? false : true;
	const label = renderLabel({
		qty: item.qty,
		salesUnit: item?.pv?.pu_unit_label,
		baseUnit: item?.pv?.pu_base_unit_label,
		unitMultiplier: item?.pv?.pu_multiplier
	});
	const getDiscountPercentage = (price: number, mrp: number) => Math.round(((mrp - price) / mrp) * 100);
	const getPrices = (item: any) => {
		const price = item.pv[`pv_${userType}_price`] * item.qty;
		const mrp = item.pv[`pv_${userType}_mrp`] * item.qty;

		return {
			price: formatPrice(price),
			mrp: formatPrice(mrp)
		};
	};

	const { stock, isExpress } = checkDeliveryAndStock(item, item.pv);
	const optionsData: any = generateQuantityOptions(
		item.pv[`pv_${userType}_min_qty`],
		item.pv[`pv_${userType}_max_qty`],
		item.pv[`pu_unit_label`],
		item?.pv?.pu_multiplier,
		item.pv[`pu_base_unit_label`]
	);

	let deliveryMessage = null;

	if (item?.p_is_not_allowed_outside_dhaka && !isDhakaCity) {
		deliveryMessage = item?.pv?.pv_not_allowed_outside_dhaka_message?.bn || 'এই পণ্যটি ঢাকার বাইরে সরবরাহযোগ্য নয়।';
	} else if (!stock) {
		deliveryMessage = '';
	} else if (!isDhakaCity && !!item?.p_cold) {
		deliveryMessage =
			item?.pv?.pv_cold_message?.bn ||
			' দুঃখিত এই মেডিসিনটি তাপ সংবেদনশীল, তাই ঢাকা শহরের বাইরে আপাতত ডেলিভারি হচ্ছে না।';
	}
	// console.log('deliveryMessage', stock);

	return (
		<Fragment key={key}>
			<div className={styles.items__item}>
				<div className='d-flex justify-content-between w-full'>
					<div
						className={styles.items__left}
						style={{
							width: '75%'
						}}>
						<div className={styles.item__image}>
							<Link
								href={generateProductLink(item, item.pv)}
								style={{
									...(!stock && {
										opacity: 0.5
									})
								}}>
								<Image
									loader={imageLoader}
									width={84}
									height={84}
									unoptimized={true}
									placeholder='blur'
									blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(117, 117))}`}
									src={getProductImage(item)}
									alt={item.p_name}
									style={{ objectFit: 'cover' }}
								/>
								{getPrices(item).price !== getPrices(item).mrp && (
									<span className={styles.item__discount}>
										<span>{getDiscountPercentage(getPrices(item).price, getPrices(item).mrp)}% OFF</span>
									</span>
								)}
							</Link>
							{!stock && (
								<div className={styles.outofstock}>
									<div className={styles.stocks_message}>Out of stock</div>
								</div>
							)}
						</div>
						<div className={styles.item__details}>
							<div className={styles.item_name}>
								<Link href={generateProductLink(item, item.pv)} className={styles.item_name}>
									<Tooltip content={item.p_name} size='small'>
										{item.p_name}
									</Tooltip>
								</Link>
							</div>
							{item.p_strength && (
								<div
									className='text-10 text-grey500 fw-400'
									style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}>
									<Tooltip content={`${item.p_strength} ${item.p_form && ` - ${item.p_form}`}`} size='small'>
										{item.p_strength} {item.p_form && ` - ${item.p_form}`}
									</Tooltip>
								</div>
							)}
							{item.p_brand && (
								<div
									className='text-10 text-grey900 fw-400'
									style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}>
									<Tooltip content={item.p_brand} size='small'>
										{item.p_brand}
									</Tooltip>
								</div>
							)}

							{item.qty === 0 ? null : (
								<div
									className='text-10 text-grey500 fw-300'
									style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80%' }}>
									<Tooltip content={label} size='small'>
										{label}
									</Tooltip>
								</div>
							)}

							{Object.keys(currentPv?.pv_attribute || {}).length > 0 &&
								map(currentPv.pv_attribute, (value, key) => (
									<div
										key={key}
										className='text-10 text-grey900 fw-400 text-capitalize'
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											width: '90%'
										}}>
										<Tooltip content={`${key.toLocaleUpperCase()}: ${value}`} size='small'>
											{key} : &nbsp;
											{value}
										</Tooltip>
									</div>
								))}
							{qty_decrease_message && !deliveryMessage ? (
								<div
									className='text-10 fw-300'
									style={{
										color: '#FD4F4F',
										width: '80%'
									}}>
									<Tooltip content={qty_decrease_message} size='small'>
										Quantity updated: {qty_decrease_message}
									</Tooltip>
								</div>
							) : (
								<>
									{deliveryMessage &&
										cartsUcAddress &&
										typeof cartsUcAddress === 'object' &&
										Object.keys(cartsUcAddress).length > 0 && (
											<div className='text-10 text-error fw-400 '>{deliveryMessage}</div>
										)}
								</>
							)}
						</div>
					</div>

					{/* Right Section */}

					<div
						className={styles.tems__right}
						style={{
							display: (showItemsRight === true && stock) || showNotify === true ? 'flex' : 'none',
							width: '25%',
							flexDirection: 'column',
							justifyContent: 'space-between',
							alignItems: 'flex-end',
							position: 'relative'
						}}>
						{showNotify && (
							<Fragment>
								<div
									style={{
										display: 'flex',
										cursor: 'pointer',
										justifyContent: 'center',
										gap: '5px',
										alignItems: 'center'
									}}>
									{!!item?.p_cold && (
										<Tooltip content='Cold Item' size='small'>
											<IC_COLD style={{ height: '22px', width: '22px' }} />
										</Tooltip>
									)}
									{!!item?.p_rx_req && (
										<Tooltip content='Prescription Required' size='small'>
											<IC_RX style={{ height: '20px', width: '20px' }} />
										</Tooltip>
									)}
								</div>
							</Fragment>
						)}
						{showItemsRight === true && (
							<Fragment>
								<div
									style={{
										display: 'flex',
										cursor: 'pointer',
										justifyContent: 'center',
										gap: '5px',
										alignItems: 'center'
									}}>
									{!!item?.p_cold && (
										<Tooltip content='Cold Item' size='small'>
											<IC_COLD style={{ height: '22px', width: '22px' }} />
										</Tooltip>
									)}
									{!!item?.p_rx_req && (
										<Tooltip content='Prescription Required' size='small'>
											<IC_RX style={{ height: '20px', width: '20px' }} />
										</Tooltip>
									)}
									{isDhakaCity ? (
										<>
											{deleveryType ? (
												<Tooltip content='Regular Delivery' size='small'>
													<IC_REGULAR style={{ height: '18px', width: '18px' }} />
												</Tooltip>
											) : (
												<Tooltip content='Express Delivery' size='small'>
													<IC_EXPRESS style={{ height: '18px', width: '18px' }} />
												</Tooltip>
											)}
										</>
									) : (
										<Tooltip content='Regular Delivery' size='small'>
											<IC_REGULAR style={{ height: '18px', width: '18px' }} />
										</Tooltip>
									)}
									<Tooltip content='Remove' size='small'>
										{removeIcon}
									</Tooltip>
								</div>

								<div className='d-flex justify-content-center align-items-center'>
									<del className='text-12 text-grey500 mr-10'>৳{getPrices(item).mrp}</del>
									<div className='text-12 text-grey900 fw-600'>৳{getPrices(item).price}</div>
								</div>
							</Fragment>
						)}
						{/* Quantity Selector */}
						<div className='d-flex justify-content-center align-items-center'>
							{!ButtonComponent && !isUnavailable ? (
								<SelectCart
									valueStyle={{ color: '#0E7673', fontWeight: '500' }}
									style={{
										display: item.qty === 0 ? 'none' : 'flex',
										// display: 'flex',
										width: '80px',
										height: '30px',
										borderRadius: '5px',
										alignItems: 'center',
										fontSize: '12px',
										fontWeight: '500',
										border: '1px solid #0E7673',
										color: '#0E7673'
									}}
									ellipsis={false}
									size='small'
									prefix='Qty:'
									value={item.qty || 1}
									defaultValue={item.qty}
									// options={options as any}
									options={optionsData}
									onChange={(e: any) => {
										const newCart = {
											id: item.id,
											quantity: e,
											variant: item.pv.id,
											baseUnit: item.pu.id
										};
										cartAction(newCart);
									}}
								/>
							) : (
								<>{!stock && ButtonComponent}</>
							)}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CartItem;
