'use client';
import { IC_DELETE } from '@/assets/icons';
import { IM_PAYMENTS_ICONS_V2 } from '@/assets/images';
import Button from '@/components/atoms/Button';
import Card from '@/components/atoms/Card';
import Modal from '@/components/atoms/Modal';
import OrBreak from '@/components/atoms/OrBreak/OrBreak';
import OrderInfoBox from '@/components/atoms/OrderInfoBox/OrderInfoBox';
import Radio from '@/components/atoms/Radio';
import SpinnerWrapper from '@/components/atoms/SpinnerWrapper';
import AcknowledgeCongratulations from '@/components/dynamics/acknowledgeCongratulations/AcknowledgeCongratulations';
import CartItem from '@/components/pageComponents/cartItems/CartItems';
import { useAlert } from '@/contexts/AlertProvider';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import useTimer from '@/services/hooks/useTimer';
import { generateQuantityOptions, newWindowLoad } from '@/services/utils/helper';
import { filter, get, isEmpty } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Fragment, useEffect, useState } from 'react';
import { MdInfo, MdOutlineClose } from 'react-icons/md';
import OrderSummary from '../OrderSummary/OrderSummary';
import OrderSummaryCheckOut from '../OrderSummary/OrderSummaryCheckOut';
import styles from './CheckoutModal.module.scss';

const CheckoutModal = ({
	isOpen,
	onClose,
	carts,
	setIsOpen,
	hasQuantityDecreased,
	setHasQuantityDecreased,
	userType,
	orderLoading,
	isOrderPlaced,
	orderData,
	handleOrder
}: any) => {
	const router = useRouter();
	const {
		setIsPayementLoading,
		removeCart,
		cartAction,
		paymentSuccessModal,
		setPaymentSuccessModal,
		setPaymentSuccessModalData
	} = useCart();
	const timerOnFinished = () => {
		router.push('/');
	};
	const { minutes, seconds, startTimer, stopTimer, isActive, resetTimer, setTime } = useTimer(30, timerOnFinished);
	const [paymentMethod, setPaymentMethod] = useState('online');
	const [timeLeft, setTimeLeft] = useState(
		orderData?.po_payment_threshold ? parseInt(orderData?.po_payment_threshold) * 60 : 0
	);
	const alert = useAlert();
	const { user } = useAuth();

	const showPamynetOption = !isEmpty(orderData?.payment_url);
	//hasQuantityDecreased
	const hasProducts = get(carts, 'products.length', 0) > 0;
	const hasUnavailableProducts = get(carts, 'unavailable_items.length', 0) > 0;
	const isDhakaCity = get(orderData, 'full_shipping_address.l_district') === 'Dhaka City';
	const isDhakaCityforQty = get(carts, 'uc_address.l_district') === 'Dhaka City';

	const filteredCarts = [...(carts?.products || []), ...(carts?.unavailable_items || [])].filter(Boolean);
	const isCodAvailable = false;
	const isCodAvailableCompireMaxCod = orderData?.po_is_soft_order;

	useEffect(() => {
		setTimeLeft(orderData?.po_payment_threshold ? parseInt(orderData?.po_payment_threshold) * 60 : 0);
		if (orderData?.po_is_soft_order) {
			resetTimer();
			setTime(parseInt(orderData?.po_payment_threshold) * 60);
			startTimer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderData]);

	useEffect(() => {
		if (!timeLeft) return;

		const intervalId = setInterval(() => {
			setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timeLeft]);

	// Format time as mm:ss
	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
	};

	const handleOnlinePayment = async () => {
		if (paymentMethod === 'online' && orderData?.payment_url) {
			newWindowLoad(orderData?.payment_url, orderData?.po_id, router, setIsPayementLoading, alert, {
				paymentSuccessModal,
				setPaymentSuccessModal,
				setPaymentSuccessModalData
			});
			setIsOpen(false);
		}
	};
	const isPrescriptionOrderOnly = orderData?.po_item_count === 0 && orderData?.po_prescriptions?.length > 0;
	const isPaid = orderData?.po_payment_status === 'paid' || isPrescriptionOrderOnly;
	const globalCODAvailable = carts?.is_cod_available_outside_dhaka === 1 ? true : false;
	const isMaxCod = orderData?.maxCod < orderData?.po_payable_total;
	const showCODOptions =
		(!isDhakaCity || (isDhakaCity && isMaxCod) || isCodAvailableCompireMaxCod) && (!globalCODAvailable || isMaxCod);
	return (
		<>
			{hasQuantityDecreased ? (
				<Modal
					isOpen={hasQuantityDecreased}
					onClose={() => {
						onClose();
						setPaymentMethod(null);
						setHasQuantityDecreased(false);
					}}
					footer={null}
					width={600}
					maskClosable={false}
					title='Updated Shopping Cart'
					// subTitle={
					// 	carts?.has_qty_restored
					// 		? 'The Stock of the following item(s) has available:'
					// 		: 'The Stock of the following item(s) has decreased'
					// }
					subTitle={'Item quantities updated as per available stock.'}
					bodyStyle={{
						borderRadius: '15px',
						padding: '0px 20px',
						maxHeight: 'calc(100vh - 200px)',
						overflowY: 'auto'
					}}>
					{(hasProducts || hasUnavailableProducts) && (
						<div className={styles.drawer__body__items}>
							{/*CartItem 3  */}
							{filteredCarts?.map((item: any, i: any) => {
								const isUnavailable = filter(carts?.unavailable_items, { pv: { id: item?.pv?.id } }).length > 0;
								return (
									<CartItem
										key={i}
										item={item}
										userType={userType}
										isDhakaCity={isDhakaCityforQty}
										cartAction={cartAction}
										options={generateQuantityOptions(
											item.pv[`pv_${userType}_min_qty`],
											item.pv[`pv_${userType}_max_qty`],
											item.pv[`pu_unit_label`],
											item?.pv?.pu_multiplier,
											item.pv[`pu_base_unit_label`]
										)}
										showItemsRight={isUnavailable ? false : true}
										removeIcon={
											isUnavailable && <IC_DELETE onClick={() => removeCart(item?.pv?.id, item?.p_name)} />
										}
										cartsUcAddress={carts?.uc_address}
										currentPv={get(item, 'pv', {})}
										qty_decrease_message={item?.poi_stocks}
										isUnavailable={isUnavailable}
										showNotify={isUnavailable}
									/>
								);
							})}
						</div>
					)}

					{carts && carts?.po_item_count !== 0 ? (
						<div className='my-10'>
							<OrderSummary data={carts} />
						</div>
					) : null}

					{/* Fixed at the bottom */}

					{hasUnavailableProducts && !hasProducts ? (
						<div
							style={{
								position: 'sticky',
								bottom: 0,
								background: '#fff',
								width: '100%',
								paddingBottom: '10px',
								zIndex: 10
							}}>
							<p
								onClick={() => {
									setHasQuantityDecreased(false);
									setTimeout(() => {
										router.push('/');
									}, 1000);
								}}
								style={{
									backgroundColor: '#0E7673',
									color: 'white',
									padding: '12px 15px',
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: '5px',
									marginTop: '10px',
									position: 'sticky',
									bottom: '20',
									zIndex: '1',
									cursor: 'pointer'
								}}>
								Continue Shopping
							</p>
						</div>
					) : (
						<div
							style={{
								position: 'sticky',
								bottom: 0,
								background: '#fff',
								width: '100%',
								paddingBottom: '10px',
								zIndex: 10
							}}>
							<div>
								<Button
									type='primary'
									className='w-full'
									onClick={() => {
										setIsOpen(true);
										setHasQuantityDecreased(false);
										handleOrder();
									}}>
									Confirm Order: ৳{carts?.po_payable_total}
								</Button>
							</div>
						</div>
					)}
				</Modal>
			) : (
				<Modal
					isOpen={isOpen}
					onClose={() => {
						onClose();
						setPaymentMethod(null);
					}}
					footer={
						isOrderPlaced ? (
							<div className={styles.stickyFooter}>
								<div className='d-flex gap-10 flex-column'>
									{/* {orderData?.responseMessage !== 'Order placed successfully' &&
										orderData?.po_is_soft_order !== 1 &&
										!isPrescriptionOrderOnly && <OrderInfoBox message={orderData?.responseMessage} />} */}

									{isPaid ? (
										<Button
											type='primary'
											className='w-full'
											onClick={() => {
												router.push('/');
												setIsOpen(false);
											}}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='21'
												height='20'
												viewBox='0 0 21 20'
												fill='none'>
												<mask
													id='mask0_24886_262421'
													maskUnits='userSpaceOnUse'
													x='0'
													y='0'
													width='21'
													height='20'>
													<rect x='0.5' width='20' height='20' fill='#fff' />
												</mask>
												<g mask='url(#mask0_24886_262421)'>
													<path
														d='M6.33203 18.3346C5.8737 18.3346 5.48134 18.1714 5.15495 17.8451C4.82856 17.5187 4.66536 17.1263 4.66536 16.668C4.66536 16.2096 4.82856 15.8173 5.15495 15.4909C5.48134 15.1645 5.8737 15.0013 6.33203 15.0013C6.79036 15.0013 7.18273 15.1645 7.50911 15.4909C7.8355 15.8173 7.9987 16.2096 7.9987 16.668C7.9987 17.1263 7.8355 17.5187 7.50911 17.8451C7.18273 18.1714 6.79036 18.3346 6.33203 18.3346ZM14.6654 18.3346C14.207 18.3346 13.8147 18.1714 13.4883 17.8451C13.1619 17.5187 12.9987 17.1263 12.9987 16.668C12.9987 16.2096 13.1619 15.8173 13.4883 15.4909C13.8147 15.1645 14.207 15.0013 14.6654 15.0013C15.1237 15.0013 15.5161 15.1645 15.8424 15.4909C16.1688 15.8173 16.332 16.2096 16.332 16.668C16.332 17.1263 16.1688 17.5187 15.8424 17.8451C15.5161 18.1714 15.1237 18.3346 14.6654 18.3346ZM4.83203 3.33464H17.1237C17.4431 3.33464 17.6862 3.477 17.8529 3.76172C18.0195 4.04644 18.0265 4.33464 17.8737 4.6263L14.9154 9.95963C14.7626 10.2374 14.5577 10.4527 14.3008 10.6055C14.0438 10.7582 13.7626 10.8346 13.457 10.8346H7.2487L6.33203 12.5013H16.332V14.168H6.33203C5.70703 14.168 5.23481 13.8937 4.91536 13.3451C4.59592 12.7964 4.58203 12.2513 4.8737 11.7096L5.9987 9.66797L2.9987 3.33464H1.33203V1.66797H4.04036L4.83203 3.33464Z'
														fill='white'
													/>
												</g>
											</svg>
											<span className='ml-5'>Start A New Order</span>
										</Button>
									) : (
										<Button
											disabled={orderLoading}
											type='primary'
											className='w-full'
											onClick={handleOnlinePayment}>
											Pay Online: ৳{orderData?.po_payable_total}
										</Button>
									)}

									{!isCodAvailableCompireMaxCod && !isPrescriptionOrderOnly && (
										<Button
											disabled={orderLoading}
											type='primary-outline'
											className='w-full'
											onClick={() => {
												router.push(`/account/orders/${orderData?.po_id}`);
												setIsOpen(false);
											}}>
											Track Order
										</Button>
									)}
								</div>
							</div>
						) : null
					}
					width={480}
					maskClosable={false}
					title={orderLoading || isPaid ? '' : 'Payment'}
					bodyStyle={{
						borderRadius: '15px',
						backgroundColor: '#F9FAFB',
						padding: !isPaid ? '10px 20px' : '0px',
						maxHeight: 'calc(100vh - 200px)',
						overflowY: 'auto',
						position: 'relative'
					}}>
					{orderLoading ? (
						<SpinnerWrapper loading={orderLoading}>
							<div
								style={{
									minHeight: '200px',
									width: '100%'
								}}></div>
						</SpinnerWrapper>
					) : (
						<>
							{isPaid && (
								<div
									style={{
										position: 'absolute',
										right: 0,
										top: 0,
										padding: '10px',
										zIndex: 10,
										color: '#98A2B3'
									}}>
									<div
										style={{ cursor: 'pointer' }}
										onClick={() => {
											onClose();
										}}>
										<MdOutlineClose size={24} className='hover-text-error' />
									</div>
								</div>
							)}
							{(!isCodAvailableCompireMaxCod || isPrescriptionOrderOnly) && (
								<Card
									bordered={false}
									size='small'
									bodyStyle={
										isPaid
											? {
													padding: '16px',
													margin: '0px 0px 0px 0px'
												}
											: {}
									}>
									<AcknowledgeCongratulations
										step={isPaid ? 2 : 1}
										showStep={isPrescriptionOrderOnly ? false : true}
										data={orderData}
										isCollapsible={!isPaid && !isPrescriptionOrderOnly}
										message={
											isPaid ? (
												isPrescriptionOrderOnly ? (
													orderData?.responseMessage
												) : (
													<>Order placed and paid successfully.</>
												)
											) : (
												<>
													Order placed successfully by
													<strong style={{ color: '#1F2937' }}> &quot;Cash On Delivery&quot;.</strong>
												</>
											)
										}
									/>
								</Card>
							)}
							{!isPaid && (
								<Card
									size='small'
									bordered={false}
									title={
										!isCodAvailableCompireMaxCod ? (
											'Do you want to pay now?'
										) : (
											<p className='fw-600'>
												Confirm payment within{' '}
												<strong style={{ color: 'green' }}>{formatTime(timeLeft)}</strong> minutes to
												complete order
											</p>
										)
									}
									bodyStyle={{
										padding: '20px'
									}}
									headerStyle={{
										borderRadius: '12px 12px 0px 0px'
									}}>
									{showPamynetOption ? (
										<div className='d-flex flex-column gap-20'>
											<Radio.Group
												name='address'
												style={{
													display: 'flex',
													flexDirection: 'column',
													gap: 20
												}}
												defaultValue={paymentMethod}
												onChange={(e) => {
													const selectedValue = e.target.value;
													if (!isCodAvailable && selectedValue === 'cod') {
														alert.warning({
															title: 'Warning',
															message: 'COD is not available'
														});
														setPaymentMethod('online');
													} else {
														setPaymentMethod(e.target.value);
													}
												}}>
												<Radio
													value='online'
													label='Online Payment'
													onClick={() => {
														setPaymentMethod('online');
													}}>
													<span className='text-16 fw-500'>Pay Online</span>
												</Radio>

												<div style={{ display: 'flex' }}>
													<Image
														src={IM_PAYMENTS_ICONS_V2.src}
														width={408}
														height={120}
														alt='payment icons'
														unoptimized
													/>
												</div>

												{showCODOptions && (
													<>
														<OrBreak />
														<div className='d-flex gap-8 flex-column'>
															<Radio
																value='cod'
																disabled={!isCodAvailable}
																onClick={() => {
																	const selectedValue = 'cod';
																	if (!isCodAvailable && selectedValue === 'cod') {
																		alert.warning({
																			title: 'Warning',
																			message: 'COD is not available'
																		});
																		setPaymentMethod('online');
																	} else {
																		setPaymentMethod('cod');
																	}
																}}
																style={
																	!isCodAvailable
																		? {
																				cursor: 'not-allowed'
																			}
																		: {}
																}>
																<span
																	className='text-grey900 fw-700'
																	style={
																		isCodAvailable ? { cursor: 'pointer' } : { cursor: 'not-allowed' }
																	}
																	onClick={() => {
																		if (!isCodAvailable) {
																			alert.warning({
																				title: 'Warning',
																				message: 'COD is not available'
																			});
																		}
																	}}>
																	{isCodAvailable ? (
																		'Cash on delivery (COD)'
																	) : (
																		<>
																			<div className='text-grey500'>Cash on delivery (COD)</div>
																		</>
																	)}
																</span>
															</Radio>
															<>
																<p className='bg-info p-6 rounded-4 d-flex gap-6 w-full items-center'>
																	<MdInfo style={{ color: '#2970FF' }} />

																	{isDhakaCity ? (
																		<>
																			{isMaxCod && (
																				<span className='text-grey700  fw-500 text-14 '>
																					<strong>Cash on Delivery</strong> is not available over{' '}
																					<strong>৳{orderData?.maxCod}</strong>
																				</span>
																			)}
																			{!globalCODAvailable && !isMaxCod && (
																				<span className='text-grey700  fw-500 text-14 '>
																					<strong>Cash on Delivery</strong> is not available for{' '}
																					<strong>Outside Dhaka.</strong>
																				</span>
																			)}
																		</>
																	) : (
																		<>
																			{globalCODAvailable && isMaxCod ? (
																				<span className='text-grey700  fw-500 text-14 '>
																					<strong>Cash on Delivery</strong> is not available over{' '}
																					<strong>৳{orderData?.maxCod}</strong>
																				</span>
																			) : (
																				<span className='text-grey700  fw-500 text-14 '>
																					<strong>Cash on Delivery</strong> is not available for{' '}
																					<strong>Outside Dhaka.</strong>
																				</span>
																			)}
																		</>
																	)}
																</p>
															</>
														</div>
													</>
												)}
											</Radio.Group>
										</div>
									) : (
										<div className='d-flex flex-column gap-20 fw-500'>
											{user.u_cash_balance > 0 ? (
												<span>Your Payment will be deducted from your Arogga Cash Balance</span>
											) : (
												<div className='text-14 text-grey500'>
													Our customer service will call you to confirm your order and payment amount
												</div>
											)}
										</div>
									)}
								</Card>
							)}

							{!isPaid && orderData && <OrderSummaryCheckOut data={orderData} />}
						</>
					)}
				</Modal>
			)}
		</>
	);
};

export default CheckoutModal;
