'use client';
import useOutsideClick from '@labServices/hooks/useOutsideClick';
import { useRef } from 'react';
import styles from './Popover.module.css';

const Popover = ({ variant, open, handleClose, children }) => {
	const ref = useRef();
	const className = `${styles.popover} ${variant ? styles[variant] : ''} ${open ? styles.open : ''}`;
	useOutsideClick(ref, handleClose);

	return (
		<div ref={ref} className={className}>
			{children}
		</div>
	);
};

export default Popover;
