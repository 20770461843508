import { getFlatNumber } from '@labServices/utils/cmnHelper';
import { APP_URL } from '../apis/config';
import useAnalytics from './useAnalytics';

const useLabTestPixalEvent = () => {
	const { fbEvent, firebaseEvent, mixpanelEvent } = useAnalytics();

	const LabTestAddToCartEvent = (payload: any) => {
		const { testData, patientCount } = payload;

		fbEvent('AddToCart', {
			content_ids: [testData?.id.toString()],
			content_name: testData?.name?.en,
			content_type: 'web_lab_test',
			contents: [
				{
					id: testData?.id.toString(),
					quantity: patientCount,
					...testData
				}
			],
			currency: 'BDT',
			value: getFlatNumber(testData?.discountPrice)
		});

		firebaseEvent('add_to_cart', {
			content_ids: [testData?.id.toString()],
			content_name: testData?.name?.en,
			content_type: 'web_lab_test',
			items: [
				{
					item_id: testData?.id,
					item_name: testData?.name?.en,
					price: getFlatNumber(testData?.discountPrice)
				}
			],
			currency: 'BDT',
			value: getFlatNumber(testData?.discountPrice)
		});

		mixpanelEvent('add_to_cart', {
			content_type: 'web_lab_test',
			content_id: testData?.id,
			content_name: testData?.name?.en,
			value_to_sum: getFlatNumber(testData?.discountPrice * patientCount),
			quantity: patientCount,
			price: getFlatNumber(testData?.discountPrice)
		});
	};

	const LabTestViewContentEvent = (payload: any) => {
		fbEvent('view_item', {
			content_ids: [payload?.id?.toString()],
			content_name: payload?.name?.en,
			content_type: 'web_lab_test',
			contents: [
				{
					...payload,
					quantity: 1
				}
			],
			currency: 'BDT',
			value: getFlatNumber(payload?.discountPrice)
		});

		firebaseEvent('view_item', {
			content_ids: [payload?.id?.toString()],
			content_name: payload?.name?.en,
			content_type: 'web_lab_test',
			items: [
				{
					item_id: payload?.id,
					item_name: payload?.name?.en,
					price: getFlatNumber(payload?.discountPrice),
					quantity: 1
				}
			],
			currency: 'BDT',
			value: getFlatNumber(payload?.discountPrice)
		});

		mixpanelEvent('view_item', {
			content_type: 'web_lab_test',
			content_id: payload?.id?.toString(),
			content_name: payload?.name?.en,
			value: getFlatNumber(payload?.discountPrice)
		});
	};

	const LabTestSearchEvent = (payload: any) => {
		fbEvent('Search', {
			search_string: payload.search,
			content_type: 'web_lab_test'
		});
		firebaseEvent('search', {
			search_term: payload.search,
			content_type: 'web_lab_test'
		});

		mixpanelEvent('search_term', {
			search_term: payload.search,
			content_type: 'web_lab_test'
		});
	};

	const LabTestCheckoutEvent = (payload: any) => {
		fbEvent('InitiateCheckout', {
			content_type: 'web_lab_test',
			content_ids: payload?.items?.map((e) => e?.item?.id.toString()),
			contents: payload?.items?.map((e) => {
				return { ...e?.item, id: e?.item?.id, quantity: e?.patientCount };
			}),
			currency: 'BDT',
			num_items: payload?.itemCount,
			coupon: payload?.promoCode,
			hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
			value: payload?.subtotalAmount
		});

		firebaseEvent('begin_checkout', {
			content_type: 'web_lab_test',
			content_ids: payload?.items?.map((e) => e?.item?.id.toString()),
			items: payload?.items?.map((e) => {
				return { ...e?.item, id: e?.item?.id, quantity: e?.patientCount };
			}),
			currency: 'BDT',
			num_items: payload?.itemCount,
			coupon: payload?.promoCode,
			hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
			value: payload?.subtotalAmount
		});

		mixpanelEvent('initiate_checkout', {
			content_type: 'web_lab_test',
			contents: payload?.items?.map((e) => {
				return { item_id: e?.item?.id, quantity: e?.patientCount };
			}),
			coupon: payload?.promoCode,
			hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
			value: payload?.subtotalAmount
		});
	};

	const LabTestOrderPurchaseEvent = (payload: any) => {
		fbEvent('Purchase', {
			content_type: 'web_lab_test',
			content_ids: payload?.items?.map((e) => e?.id.toString()),
			contents: payload?.items?.map((e) => {
				return { ...e, id: e?.id, quantity: e?.patientCount };
			}),
			order_id: payload?.orderNumber,
			currency: 'BDT',
			num_items: payload?.itemCount,
			schedule_date: payload?.formattedScheduleDate,
			schedule_time: payload?.scheduleTimeRange?.en,
			payment_method: payload?.paymentMethod,
			coupon: payload?.promoCode,
			hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
			value: payload?.isHardCopyRequired
				? getFlatNumber(payload?.totalAmount)
				: getFlatNumber(payload?.totalAmountWithoutConveyance)
		});

		firebaseEvent('purchase', {
			content_type: 'web_lab_test',
			content_ids: payload?.items?.map((e) => e?.id.toString()),
			items: payload?.items?.map((e) => {
				return { ...e, id: e?.id, quantity: e?.patientCount };
			}),
			order_id: payload?.orderNumber,
			currency: 'BDT',
			num_items: payload?.itemCount,
			schedule_date: payload?.formattedScheduleDate,
			schedule_time: payload?.scheduleTimeRange?.en,
			payment_method: payload?.paymentMethod,
			coupon: payload?.promoCode,
			hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
			value: payload?.isHardCopyRequired
				? getFlatNumber(payload?.totalAmount)
				: getFlatNumber(payload?.totalAmountWithoutConveyance)
		});

		mixpanelEvent('purchase', {
			content_type: 'web_lab_test',
			contents: payload?.items?.map((e) => {
				return { ...e?.item, id: e?.item?.id, quantity: e?.patientCount };
			}),
			order_id: payload?.orderNumber,
			schedule_date: payload?.formattedScheduleDate,
			schedule_time: payload?.scheduleTimeRange?.en,
			payment_method: payload?.paymentMethod,
			content_count: payload?.itemCount,
			coupon: payload?.promoCode,
			hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
			value: payload?.isHardCopyRequired
				? getFlatNumber(payload?.totalAmount)
				: getFlatNumber(payload?.totalAmountWithoutConveyance)
		});
	};

	// LabTest Banner Events

	const LabTestBannerClickEvent = (payload: any) => {
		fbEvent('banner_click', {
			content_type: 'web_lab_test',
			banner_url: payload?.bannerUrl,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`,
			campaign_id: payload?.campaignId,
			campaign_name: payload?.campaignName
		});
		firebaseEvent('banner_click', {
			content_type: 'web_lab_test',
			banner_url: payload?.bannerUrl,
			redirectUrl: payload?.redirectUrl,
			campaign_id: payload?.campaignId,
			campaign_name: payload?.campaignName
		});

		mixpanelEvent('banner_click', {
			content_type: 'web_lab_test',
			banner_url: payload?.bannerUrl,
			redirectUrl: payload?.redirectUrl,
			campaign_id: payload?.campaignId,
			campaign_name: payload?.campaignName
		});
	};

	const LabTestBlockClickEvent = (payload: any) => {
		fbEvent('block_click', {
			content_type: 'web_lab_test',
			block_name: payload?.blockName,
			content_name: payload?.contentName,
			content_id: payload?.contentId,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`,
			price: payload?.price
		});
		firebaseEvent('block_click', {
			content_type: 'web_lab_test',
			block_name: payload?.blockName,
			content_name: payload?.contentName,
			content_id: payload?.contentId,
			redirect_url: payload.redirectUrl,
			price: payload?.price
		});

		mixpanelEvent('block_click', {
			content_type: 'web_lab_test',
			block_name: payload?.blockName,
			content_name: payload?.contentName,
			content_id: payload?.contentId,
			redirect_url: payload.redirectUrl,
			price: payload?.price
		});
	};

	const LabTestCategoryClickEvent = (payload: any) => {
		fbEvent('category_click', {
			content_type: 'web_lab_test',
			category_name: payload?.categoryName,
			content_name: payload?.contentName,
			content_id: payload?.contentId,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`,
			price: payload?.price
		});
		firebaseEvent('category_click', {
			content_type: 'web_lab_test',
			category_name: payload?.categoryName,
			content_name: payload?.contentName,
			content_id: payload?.contentId,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`,
			price: payload?.price
		});

		mixpanelEvent('category_click', {
			content_type: 'web_lab_test',
			category_name: payload?.categoryName,
			content_name: payload?.contentName,
			content_id: payload?.contentId,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`,
			price: payload?.price
		});
	};

	const MenuClickEvent = (payload: any) => {
		fbEvent('menu_click', {
			content_type: 'web_lab_test',
			content_name: payload?.contentName,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`
		});
		firebaseEvent('menu_click', {
			content_type: 'web_lab_test',
			content_name: payload?.contentName,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`
		});

		mixpanelEvent('menu_click', {
			content_type: 'web_lab_test',
			content_name: payload?.contentName,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`
		});
	};
	const LabTestMenuClickEvent = (payload: any) => {
		fbEvent('menu_click', {
			content_type: 'web_lab_test',
			content_name: payload?.contentName,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`
		});
		firebaseEvent('menu_click', {
			content_type: 'web_lab_test',
			content_name: payload?.contentName,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`
		});

		mixpanelEvent('menu_click', {
			content_type: 'web_lab_test',
			content_name: payload?.contentName,
			redirect_url: `${APP_URL}${payload?.redirectUrl}`
		});
	};

	const LabTestBannerToAddToCartEvent = (payload: any) => {
		const { testData, patientCount } = payload;

		fbEvent('AddToCart', {
			content_ids: [testData?.id.toString()],
			content_name: testData?.name?.en,
			content_type: 'web_lab_test',
			contents: [
				{
					id: testData?.id.toString(),
					quantity: patientCount,
					...testData
				}
			],
			currency: 'BDT',
			value: parseInt(testData?.discountPrice).toFixed(0)
		});

		firebaseEvent('add_to_cart', {
			content_ids: [testData?.id.toString()],
			content_name: testData?.name?.en,
			content_type: 'web_lab_test',
			items: [
				{
					item_id: testData?.id,
					item_name: testData?.name?.en,
					price: parseInt(testData?.discountPrice).toFixed(0)
				}
			],
			currency: 'BDT',
			value: parseInt(testData?.discountPrice).toFixed(0)
		});

		mixpanelEvent('add_to_cart', {
			content_type: 'web_lab_test',
			content_id: testData?.id,
			content_name: testData?.name?.en,
			item: {
				...testData,
				quantity: patientCount
			}
		});
	};

	return {
		LabTestAddToCartEvent,
		LabTestViewContentEvent,
		LabTestSearchEvent,
		LabTestCheckoutEvent,
		LabTestOrderPurchaseEvent,

		// Banner Events
		LabTestBannerClickEvent,
		LabTestBlockClickEvent,
		LabTestCategoryClickEvent,
		MenuClickEvent
	};
};

export default useLabTestPixalEvent;
