import { IC_EMPTY_CART } from '@/assets/icons';
import Button from '@labtest/presentation/atoms/Button';

import styles from './EmptyCart.module.css';

const EmptyCart = ({ closeCart, router, setDrawerOpen }) => {
	return (
		<div className={styles.emptyCart}>
			<div className={styles.empty_image_wrap}>
				<IC_EMPTY_CART />
			</div>
			<div className={`${styles.empty_text_container} bg-secondary100`}>
				<p className={styles.empty_text}>Your cart is empty</p>
			</div>
			<Button
				variant='primary'
				onClick={() => {
					closeCart();
					setDrawerOpen(false);
					router.push('/lab-test/tests');
				}}>
				Add Tests/Packages
			</Button>
		</div>
	);
};

export default EmptyCart;
