import labTestEmptyImage from '@labtest/ltassets/testCard_Empty_Image.png';
import DiscountChip from '@labtest/presentation/atoms/DiscountChip';
import BookTestButton from '@labtest/presentation/organisms/BookTestButton';
import Image from 'next/image';

import { IC_DELETE } from '@/assets/icons';
import { getFlatNumber } from '@labServices/utils/cmnHelper';
import styles from './CartTestCard.module.scss';

const CartTestCard = ({ data, removeCartItem, setShowLoginModal, handleShowTests }) => {
	return (
		<div className={styles.cartTestCard}>
			<div className={styles.cartTestCard_container}>
				<div className={styles.cartTestCard_icon}>
					<Image
						src={data?.item?.bannerImage?.web || labTestEmptyImage}
						alt={data?.item?.name.en || 'test'}
						width={85}
						height={85}
						quality={100}
						style={{
							width: '85px',
							height: '85px',
							borderRadius: '6px',
							objectFit: 'cover',
							border: '1px solid #EEEFF2'
						}}
					/>
					{getFlatNumber(data?.item?.discountPercent) > 0 && (
						<div className={styles.chip_discountPrice}>
							<DiscountChip
								height={22}
								text={`${getFlatNumber(data?.item?.discountPercent)}% OFF`}
								chipItem={'cart'}
							/>
						</div>
					)}
				</div>
				<div className={styles.cartTestCard_description}>
					<div className={styles.cartTestCard_top}>
						<div className={styles.cartTestCard_header}>
							<p className='ltbody_md__medium ltGreyscale900'>{data.item.name.en}</p>

							<div
								onClick={() => removeCartItem(data.item.id)}
								className={`${styles.cartTestCard_icon_right} cursor_pointer`}>
								<IC_DELETE />
							</div>
						</div>
						<div className={styles.cartTestCard_body}>
							<p className='ltbody_sm__regular ltGreyscale500'>{data?.item?.subtitle?.en}</p>
							<div className={styles.cartTestCard_body_price}>
								{data?.item?.discountPercent > 0 && (
									<p className='ltbody_sm__regular ltGreyscale500'>
										<del>৳{getFlatNumber(data.item.regularPrice)}</del>
									</p>
								)}
								<p className='ltbody_sm__semibold ltGreyscale900'>৳{getFlatNumber(data.totalAmount)}</p>
							</div>
						</div>
					</div>

					<div className={styles.cartTestCard_bottom}>
						<div
							onClick={(e) => {
								e.stopPropagation();
								if (data?.item?.testCount > 1) handleShowTests(data?.item);
							}}
							className={`${styles.testCard_left_btn} ${data?.item?.testCount > 1 && 'cursor_pointer text_underline'}`}>
							<p className='ltbody_sm__medium'>
								Includes {data?.itemType === 'test' ? '1 Test' : `${data?.item?.testCount} Tests`}
							</p>
						</div>
						<div className='relative'>
							<BookTestButton
								testData={data}
								testId={data.item.id}
								setShowLoginModal={setShowLoginModal}
								name={`cart_btn`}
							/>
						</div>
					</div>
				</div>
			</div>

			{data?.item?.hintMessage && (
				<div className={styles.address_error_msg}>
					<p className='errorBase'>{data?.item?.hintMessage}</p>
				</div>
			)}

			{/*  <div className={styles.cartTestCard_top}>
        <div className={styles.cartTestCard_left}>
          <div className={styles.cartTestCard_icon}>
            <Image
              src={data?.item?.bannerImage?.web}
              alt={data?.item?.name.en || 'test'}
              width={70}
              height={70}
              quality={100}
              style={{
                borderRadius: '6px',
                objectFit: 'cover',
                border: '1px solid #EEEFF2'
              }}
            />
          </div>
          <p className='ltbody_md__medium ltGreyscale900'>{data.item.name.en}</p>
        </div>
        <div onClick={() => removeCartItem(data.item.id)} className={styles.cartTestCard_icon_right}>
          <span className='material-symbols-outlined md-24'>delete</span>
        </div>
      </div>
      <div className={styles.cartTestCard_bottom}>
        {
          <div>
            {data?.item?.discountPercent > 0 && (
              <span className={styles.cartTestCard_bottom_discount}>
                <p className='ltbody_sm__semibold'>৳{getFlatNumber(data.subtotalAmount)}</p>
                <p className='ltbody_sm__semibold errorBase'>{getFlatNumber(data.item.discountPercent)}% Off</p>
              </span>
            )}
            <p className='ltbody_sm__semibold ltGreyscale900'>৳{getFlatNumber(data.totalAmount)}</p>
          </div>
        }
        <div className='relative'>
          <BookTestButton testId={data.item.id} setShowLoginModal={setShowLoginModal} />
        </div>
      </div> */}
		</div>
	);
};

export default CartTestCard;
