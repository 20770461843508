'use client';
import SpinnerWrapper from '@/components/atoms/SpinnerWrapper';
import { useAlert } from '@/contexts/AlertProvider';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import useLoginModel from '@/contexts/LoginModelProvider';
import useStickyCartContext from '@/contexts/StickyCartProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import { API_CART } from '@/services/apis/apiConstrant';
import usePixalEvent from '@/services/event/usePixalEvent';
import { handleMedicineOrder } from '@/services/utils/OrderHelper';
import { useAuthContext } from '@labServices/context';
import useLabCart from '@labServices/context/CartContext';
import useToggle from '@labServices/hooks/use-toggle';
import useOutsideClick from '@labServices/hooks/useOutsideClick';
import OrderSuccessModal from '@labtest/presentation//organisms/OrderSuccessModal';
import StateFullModal from '@labtest/presentation/atoms/Modal/StateFullModal';
import LabTestCheckoutSummary from '@labtest/presentation/molecules/LabTestCheckoutSummary';
import ModalHeader from '@labtest/presentation/molecules/ModalHeader';
import Cart from '@labtest/presentation/organisms/Cart';
import Checkout from '@labtest/presentation/organisms/Checkout';
import PopUpModal from '@labtest/presentation/organisms/PopUpModal';
import cls from 'classnames';
import { get, isEmpty, size } from 'lodash';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { Suspense, useEffect, useRef, useState } from 'react';
import { IoMdCart } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import AddressModal from '../AddressModal';
import CheckoutModal from '../CheckoutModal';
import MedicineCart, { MedicineCheckOutSummary } from './MedicineCart';
import styles from './sticky-cart.module.scss';

const Odometer = dynamic(() => import('react-odometerjs'), {
	ssr: false,
	loading: () => <span>0</span>
});

const StickyCart = () => {
	const {
		carts,
		loadingState,
		toggleDrawer,
		setDrawerOpen,
		drawerOpen,
		showAddressModal,
		setShowAddressModal,
		hasQuantityDecreased,
		setHasQuantityDecreased,
		getCart,
		hasQuantityDecreasedModal,
		setCarts,
		orderDataGlobal,
		setOrderDataGlobal,
		updateCouponExpire,
		isCouponExpire
	} = useCart();
	const [note, setNote] = useState('');
	const [subscription, setSubscription] = useState(false);
	const { isLogged, isB2B, loading }: any = useAuth();
	const { setShowLoginModal } = useLoginModel();
	const [scheduleId, setScheduleId] = useState<any>(null);
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [deliveryOption, setDeliveryOption] = useState(carts?.po_delivery_option);
	const [prescriptionLoading, setPrescriptionLoading] = useState(false);
	const [isLabCart, setIsLabCart] = useState(false);
	const userType = isB2B ? 'b2b' : 'b2c';
	const pathname = usePathname();
	const { stickyCartDetails } = useStickyCartContext();

	// Lab-test
	const { showCheckoutModal: showCheckoutModal1, setShowCheckoutModal: setShowCheckoutModal1 } = useAuthContext();
	const [openCheckout, showModal, closeModal] = useToggle();
	const [openNotfound, handleOpenNotFound, handleCloseNotfound] = useToggle();
	const [openOrderSuccessModal, handleOpenOrderSuccessModal, handleCloseOrderSuccessModal] = useToggle();
	const [notAvailableRes, setNotAvailableRes] = useState(null);
	const [labCheckoutData, setLabCheckoutData] = useState({});
	const [orderDetails, setOrderDetails] = useState({});
	const { cartData } = useLabCart();

	const ref = useRef(null);

	useOutsideClick(ref, () => {
		setDrawerOpen(false);
	});

	const handleCloseDrawer = () => {
		setShowCheckoutModal1 && setShowCheckoutModal1(false);
	};

	useEffect(() => {
		setDeliveryOption(carts?.po_delivery_option);
	}, [carts?.po_delivery_option]);

	useEffect(() => {
		if (pathname.startsWith('/lab-test')) {
			setIsLabCart(true);
		} else {
			setIsLabCart(false);
		}
	}, [pathname]);

	///////medicine checkout handler
	const [orderLoading, setOrderLoading] = useState(false);
	const [isOrderPlaced, setIsOrderPlaced] = useState(false);
	const [orderData, setOrderData] = useState(null);
	const alert = useAlert();
	const { EventPurchase } = usePixalEvent();

	const handleOrder = async (confirm = false) => {
		const isshowStockProductDecreasedModal =
			orderData?.has_qty_decreased || orderData?.has_qty_restored || orderData?.has_unavailable_items;
		if (!orderData?.prescription_req && isCouponExpire && !isshowStockProductDecreasedModal) {
			alert.warning({
				title: 'Your coupon has expired!',
				message: 'Do you want to continue without a coupon?',
				confirmText: 'Proceed',
				onConfirm: () => {
					updateCouponExpire(false);
					setShowCheckoutModal(false);
					setHasQuantityDecreased(false);
					setOrderLoading(true);
					setIsOrderPlaced(false);
					handleMedicineOrder(
						setShowCheckoutModal,
						setOrderLoading,
						setIsOrderPlaced,
						deliveryOption,
						scheduleId,
						subscription,
						note,
						carts,
						getCart,
						setOrderData,
						setDrawerOpen,
						AroggaAPI as any,
						API_CART,
						EventPurchase,
						alert,
						confirm,
						orderDataGlobal,
						setOrderDataGlobal,
						setCarts,
						isCouponExpire
					);

					setDrawerOpen(false);
				},
				cancelText: 'Cancel',
				onCancel: () => {
					setOrderLoading(true);
					setIsOrderPlaced(false);
					setDrawerOpen(true);
					setShowCheckoutModal(false);
					setTimeout(getCart, 1000);
				}
			});
			return;
		}

		handleMedicineOrder(
			setShowCheckoutModal,
			setOrderLoading,
			setIsOrderPlaced,
			deliveryOption,
			scheduleId,
			subscription,
			note,
			carts,
			getCart,
			setOrderData,
			setDrawerOpen,
			AroggaAPI as any,
			API_CART,
			EventPurchase,
			alert,
			confirm,
			orderDataGlobal,
			setOrderDataGlobal,
			setCarts,
			isCouponExpire
		);
	};

	useEffect(() => {
		if (!isEmpty(orderData)) {
			if (!orderData?.is_order_created) {
				let message = '';
				let subtitle = '';
				let shouldReturn = false;
				let canOrder = false;

				if (orderData.has_qty_decreased || orderData.has_qty_restored || orderData.has_unavailable_items) {
					setIsOrderPlaced(false);
					setOrderLoading(false);
					setCarts(carts);
					setShowCheckoutModal(false);
					setHasQuantityDecreased(true);
					if (orderData.has_coupon_expired) {
						updateCouponExpire(true);
					}
					return;
				}

				if (orderData.prescription_req || orderData.has_coupon_expired) {
					if (orderData.prescription_req && orderData.has_coupon_expired) {
						updateCouponExpire(true);
						setShowCheckoutModal(false);
						message = 'Prescription is required!';
						shouldReturn = true;
					} else if (orderData.prescription_req) {
						message = 'Prescription is required!';
						shouldReturn = true;
						setShowCheckoutModal(false);
					} else if (orderData.has_coupon_expired) {
						message = 'Your coupon has expired!';
						subtitle = 'Do you want to continue without a coupon?';
						shouldReturn = true;
						canOrder = true;
						setShowCheckoutModal(false);
					}

					alert.warning({
						title: message,
						message: subtitle,
						confirmText: canOrder ? 'Proceed' : 'Add Prescription',
						onConfirm: () => {
							setShowCheckoutModal(false);
							setOrderData(null);

							if (canOrder) handleOrder(true);
							setOrderLoading(false);
							setHasQuantityDecreased(false);
							setDrawerOpen(!canOrder);
							setTimeout(getCart, 1000);
							if (shouldReturn) return;
						},
						cancelText: 'Cancel',
						onCancel: () => {
							setOrderData(null);
							setOrderLoading(canOrder);
							setIsOrderPlaced(false);
							setDrawerOpen(true);
							setShowCheckoutModal(false);
							setTimeout(getCart, 1000);
							if (shouldReturn) return;
						}
					});
					if (shouldReturn) return;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderData]);

	return (
		<div className={styles.sticky__cart}>
			<div
				className={`${styles.sticky__box} sticky__cart`}
				style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
				onClick={() => {
					toggleDrawer();
					setTimeout(() => {
						getCart();
					}, 1000);
				}}>
				<div className={styles.sticky__box__top}>
					<IoMdCart size={24} />
					<span>{!loadingState && isLogged && stickyCartDetails.count} Items</span>
				</div>
				<div className={styles.sticky__box__bottom}>
					<span className='text-12'>৳</span>
					<Suspense fallback={<span>0</span>}>
						<Odometer value={get(stickyCartDetails, 'total', '0')} format='(,ddd).dd' />
					</Suspense>
				</div>
			</div>
			{isLogged && showAddressModal && (
				<AddressModal isOpen={showAddressModal} setIsOpen={setShowAddressModal} hover={true} parent='drawer' />
			)}
			{isLogged && (
				<div ref={ref} className={`${styles.sticky__drawer} ${drawerOpen ? styles.open : ''}`}>
					<div className={styles.drawer__header}>
						<div className={styles.drawer__header__title}>
							<div className='text-16 text-grey900 fw-600'>Shopping Cart</div>
						</div>
						<div className={styles.drawer__header__close} onClick={toggleDrawer}>
							<MdClose className={styles.drawer__header__close__icon} size={24} />
						</div>
					</div>
					<div
						className={styles.drawer__body}
						style={{
							backgroundColor: '#fff'
						}}>
						<SpinnerWrapper loading={loadingState || prescriptionLoading}>
							<div
								className='text-14 text-grey900 fw-600 mb-10 border1-grey300 '
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.1)',
									padding: '6px 6px',
									borderRadius: '50px'
								}}>
								<div
									className={cls('py-7 px-10  cursor-pointer', {
										'bg-primary': !isLabCart,
										'text-white': !isLabCart,
										'text-black': isLabCart
									})}
									onClick={() => {
										setIsLabCart(false);
									}}
									style={{
										width: '60%',
										position: 'relative',
										display: 'flex',
										gap: '5px',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '50px',
										fontSize: 14,
										fontWeight: 500
									}}>
									Products
									{!!size(carts?.products) && (
										<span
											style={{
												display: 'inline-flex',
												backgroundColor: isLabCart ? '#FD6A6A' : '#fff',
												color: isLabCart ? '#fff' : '#FD6A6A',
												borderRadius: '50%',
												height: '20px',
												width: '20px',
												padding: '2px',
												justifyContent: 'center',
												alignItems: 'center',
												fontSize: '10px'
											}}>
											{get(carts, 'products.length', '0')}
										</span>
									)}
								</div>
								<div
									className={cls('p-7 px-10 rounded-50 cursor-pointer', {
										'bg-primary': isLabCart,
										'text-white': isLabCart,
										'text-black': !isLabCart
									})}
									onClick={() => {
										setIsLabCart(true);
									}}
									style={{
										width: '50%',
										display: 'flex',
										justifyContent: 'center',
										borderRadius: '50px',
										fontSize: 14,
										fontWeight: 500
									}}>
									Lab Tests
									{cartData?.itemCount > 0 && (
										<span
											style={{
												display: 'inline-flex',
												backgroundColor: isLabCart ? '#fff' : '#FD6A6A',
												color: isLabCart ? '#FD6A6A' : '#fff',
												borderRadius: '50%',
												height: '20px',
												width: '20px',
												padding: '2px',
												justifyContent: 'center',
												alignItems: 'center',
												fontSize: '10px',
												marginLeft: '5px'
											}}>
											{cartData?.itemCount}
										</span>
									)}
								</div>
							</div>

							{isLabCart ? (
								<>
									<Cart closeCart={handleCloseDrawer} />

									<StateFullModal open={openCheckout} closeModal={closeModal}>
										<ModalHeader title='Checkout' closeModal={closeModal} name={null} />
										{openCheckout && (
											<Checkout
												labCheckoutData={labCheckoutData}
												closeCheckoutModal={closeModal}
												handleOpenNotFound={handleOpenNotFound}
												handleOpenOrderSuccessModal={handleOpenOrderSuccessModal}
												setOrderDetails={setOrderDetails}
												setNotAvailableRes={setNotAvailableRes}
											/>
										)}
									</StateFullModal>

									{openNotfound && (
										<StateFullModal open={openNotfound} closeModal={handleCloseNotfound}>
											<PopUpModal
												modalType={`not_available`}
												title={`Sorry`}
												errorTag={notAvailableRes?.data?.errorTag}
												message={notAvailableRes?.message}
												cancelDelete={handleCloseNotfound}
												confirmDelete={handleCloseNotfound}
											/>
										</StateFullModal>
									)}

									<StateFullModal open={openOrderSuccessModal} closeModal={handleCloseOrderSuccessModal}>
										<ModalHeader name={`order_success_header`} closeModal={handleCloseOrderSuccessModal} />
										<OrderSuccessModal
											data={orderDetails}
											title={`Congratulations!`}
											message={`Your Booking Placed Successfully`}
											closeOrderSuccessModal={handleCloseOrderSuccessModal}
											closeCheckoutModal={closeModal}
										/>
									</StateFullModal>
								</>
							) : (
								<MedicineCart
									styles={styles}
									userType={userType}
									setPrescriptionLoading={setPrescriptionLoading}
									setScheduleId={setScheduleId}
									scheduleId={scheduleId}
									deliveryOption={deliveryOption}
									setDeliveryOption={setDeliveryOption}
									note={{
										note,
										setNote
									}}
									subscription={{
										subscription,
										setSubscription
									}}
								/>
							)}
						</SpinnerWrapper>
					</div>

					{/* Checkout Summaray */}

					{isLabCart ? (
						<LabTestCheckoutSummary
							isLogged={isLogged}
							showCheckoutModal={showModal}
							handleOpenNotFound={handleOpenNotFound}
							setLabCheckoutData={setLabCheckoutData}
							setNotAvailableRes={setNotAvailableRes}
							setShowLoginModal={setShowLoginModal}
							setShowCheckoutModal={setShowCheckoutModal}
							setShowAddressModal={setShowAddressModal}
						/>
					) : (
						<MedicineCheckOutSummary
							isLogged={isLogged}
							styles={styles}
							setShowLoginModal={setShowLoginModal}
							setShowCheckoutModal={setShowCheckoutModal}
							hasQuantityDecreased={hasQuantityDecreased}
							setHasQuantityDecreased={setHasQuantityDecreased}
							deliveryOption={deliveryOption}
							userType={userType}
							handleOrder={handleOrder}
						/>
					)}
				</div>
			)}
			{isLogged && (showCheckoutModal || hasQuantityDecreased || hasQuantityDecreasedModal) && !isLabCart && (
				<CheckoutModal
					isOpen={showCheckoutModal}
					setIsOpen={setShowCheckoutModal}
					hasQuantityDecreased={hasQuantityDecreased}
					setHasQuantityDecreased={setHasQuantityDecreased}
					carts={carts}
					orderLoading={orderLoading}
					isOrderPlaced={isOrderPlaced}
					userType={userType}
					orderData={orderData}
					setShowCheckoutModal={setShowCheckoutModal}
					handleOrder={handleOrder}
					onClose={() => {
						setShowCheckoutModal(false);
						setOrderData(null);
						setOrderLoading(false);
						setIsOrderPlaced(false);
						setHasQuantityDecreased(false);
						setDrawerOpen(true);
					}}
				/>
			)}
		</div>
	);
};

export default StickyCart;
