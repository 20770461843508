import Button from '@labtest/presentation/atoms/Button';
import Line from '@labtest/presentation/atoms/Line';
import Radio from '@labtest/presentation/atoms/Radio';
import TextField from '@labtest/presentation/atoms/TextField';

import {
	v1_delete_error_icon,
	v1_lab_science,
	v1_not_available_service_image,
	v1_not_available_test_image,
	v1_order_cancel_image
} from '@labServices/utils/svg';

import styles from './PopUpModal.module.css';

const PopUpModal = ({ data, title, errorTag, message, modalName, modalType, cancelDelete, confirmDelete }) => {
	const handleCheckboxChange = (name) => {
		data?.setReason((prev) => ({ ...prev, selectedReason: name || '' }));
	};

	return (
		<div className={styles.container}>
			<div className={styles.icon_container_wrap}>
				{modalType === ModalType.DELETE && <div className={styles.icon_container}>{v1_delete_error_icon}</div>}
				{modalType === ModalType.ORDER_CANCEL && (
					<div className={`${styles.icon_container} ${styles.icon_container_bg}`}>{v1_order_cancel_image}</div>
				)}

				{modalType === ModalType.NOT_AVAILABLE && errorTag && (
					<div className={styles.icon_container}>
						{errorTag === ErrorTag.ITEM_UNAVAILABLE && <>{v1_not_available_test_image}</>}
						{errorTag === ErrorTag.SERVICE_UNAVAILABLE && <>{v1_not_available_service_image}</>}
						{errorTag === ErrorTag.ITEM_MAX_REACHED && <>{v1_lab_science}</>}
						{errorTag === ErrorTag.ORDER_IN_MAINTENANCE && (
							<div className={`${styles.icon_container}`}>{v1_delete_error_icon}</div>
						)}
					</div>
				)}

				{/* Default */}
				{modalType === ModalType.NOT_AVAILABLE && !errorTag && (
					<div className={styles.icon_container}>{v1_delete_error_icon}</div>
				)}

				<div className={styles.content_container}>
					<p>{title || 'Sorry'}</p>
					<p>{message || 'Internal Server Error'}</p>
				</div>
			</div>

			<div className={styles.reasons_container_wrap}>
				{modalName === ModalName.CANCEL_ORDER && (
					<>
						<Line />
						<div className={styles.cancel_reason_container}>
							<div className={styles.lists_wrap}>
								{data?.reason?.allReasons?.map((reason, i) => (
									<div key={i} className={styles.listItem}>
										<Radio
											name={'reasons'}
											onChange={() => handleCheckboxChange(reason?.en)}
											checked={data?.reason?.selectedReason || 'Ordered by mistake'}
											// value={reason?.en}
											label={reason?.en}
											btnSize={`20px`}
											textSize={`14px_400`}
										/>
									</div>
								))}
							</div>
						</div>
					</>
				)}

				{data?.reason?.selectedReason === 'Other' && (
					<div className={styles.other_reason_container}>
						<div className={styles.textfeild}>
							<TextField
								placeholder='Reason for cancellation?'
								value={data?.reason?.otherReason}
								onChange={(e) =>
									data?.setReason((prev) => ({
										...prev,
										otherReason: e.target.value
									}))
								}
								autoFocus={true}
								maxLength={120}
								rows={2}
							/>
						</div>
					</div>
				)}
			</div>

			<div
				className={`${styles.button_container} ${modalType === ModalType.ORDER_CANCEL && styles.button_container_orderCancel}`}>
				{modalType === ModalType.NOT_AVAILABLE && (
					<Button fullWidth color='primary' onClick={cancelDelete}>
						Ok
					</Button>
				)}

				{modalType === ModalType.DELETE && (
					<>
						<Button fullWidth color='primary' onClick={cancelDelete}>
							No
						</Button>

						<Button fullWidth color='error' onClick={confirmDelete}>
							Yes
						</Button>
					</>
				)}

				{modalType === ModalType.ORDER_CANCEL && (
					<>
						<Button fullWidth color='error' onClick={cancelDelete}>
							No
						</Button>
						<Button fullWidth color='primary' onClick={confirmDelete}>
							Submit
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default PopUpModal;

const ModalName = {
	CANCEL_ORDER: 'cancel_order_modal'
};

const ModalType = {
	DELETE: 'delete',
	ORDER_CANCEL: 'order_cancel',
	NOT_AVAILABLE: 'not_available'
};

const ErrorTag = {
	ORDER_CANCEL: 'order_cancel',
	ITEM_UNAVAILABLE: 'item_unavailable',
	SERVICE_UNAVAILABLE: 'service_unavailable',
	ITEM_MAX_REACHED: 'item_max_reached',
	ORDER_IN_MAINTENANCE: 'order_in_maintenance'
};
