const OrBreak = () => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div
				style={{
					backgroundColor: '#EEEFF2',
					width: '100%',
					height: '1px'
				}}></div>
			<p
				style={{
					color: '#BDBDBD',
					fontSize: '14px',
					fontWeight: 400,
					margin: '0 7px'
				}}>
				Or
			</p>
			<div
				style={{
					backgroundColor: '#EEEFF2',
					width: '100%',
					height: '1px'
				}}></div>
		</div>
	);
};

export default OrBreak;
