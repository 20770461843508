interface IOHandler {
	setShowCheckoutModal: (value: boolean) => void;
	setOrderLoading: (value: boolean) => void;
	setIsOrderPlaced: (value: boolean) => void;
	deliveryOption: string;
	scheduleId: number;
	subscription: boolean;
	note: string;
	carts: any;
	getCart: () => void;
	setOrderData: (data: any) => void;
	setDrawerOpen: (value: boolean) => void;
	AroggaAPI: {
		post: (url: string, data: any) => Promise<{ data: any; message: string; status: 'success' | 'fail' }>;
	};
	API_CART: {
		ORDER_CREATE_FROM_CART: string;
	};
	EventPurchase: (data: {
		currency: string;
		coupon: string;
		items: { item_id: number; quantity: number; item_name: string }[];
		value: number;
		contents: any[];
		address: string;
	}) => void;
	alert: {
		error: (options: { title: string; message: string; confirmText: string; onConfirm: () => void }) => void;
		warning: (options: {
			title: string;
			message: string;
			confirmText: string;
			onConfirm: () => void;
			cancelText: string;
			onCancel: () => void;
		}) => void;
	};
	show?: boolean;
	orderDataGlobal?: any;
	setOrderDataGlobal?: any;
	setCarts?: any;
}

export const handleMedicineOrder = async (
	setShowCheckoutModal: IOHandler['setShowCheckoutModal'],
	setOrderLoading: IOHandler['setOrderLoading'],
	setIsOrderPlaced: IOHandler['setIsOrderPlaced'],
	deliveryOption: IOHandler['deliveryOption'],
	scheduleId: IOHandler['scheduleId'],
	subscription: IOHandler['subscription'],
	note: IOHandler['note'],
	carts: IOHandler['carts'],
	getCart: IOHandler['getCart'],
	setOrderData: IOHandler['setOrderData'],
	setDrawerOpen: IOHandler['setDrawerOpen'],
	AroggaAPI: IOHandler['AroggaAPI'],
	API_CART: IOHandler['API_CART'],
	EventPurchase: IOHandler['EventPurchase'],
	alert: IOHandler['alert'],
	show = false,
	orderDataGlobal,
	setOrderDataGlobal,
	setCarts,
	isCouponExpire
): Promise<void> => {
	show && setShowCheckoutModal(true);
	setOrderLoading(true);
	setIsOrderPlaced(false);

	const placeOrderData = {
		po_payment_method: 'cod',
		po_delivery_option: deliveryOption,
		po_delivery_shift_schedule_id: scheduleId,
		po_subscription_frequency: subscription ? 'MONTHLY' : null,
		po_user_notes: note || null
	};

	try {
		const { data, message, status } = await AroggaAPI.post(API_CART.ORDER_CREATE_FROM_CART, placeOrderData);

		if (status === 'fail') {
			setShowCheckoutModal(false);
			alert.error({
				title: 'Error',
				message: message,
				confirmText: 'OK',
				onConfirm: () => {
					setShowCheckoutModal(false);
					setDrawerOpen(false);
					getCart();
				}
			});
			setOrderLoading(false);
			return;
		}

		if (status === 'success' && data.is_order_created) {
			setIsOrderPlaced(true);
			EventPurchase({
				currency: 'BDT',
				coupon: carts?.d_code || '',
				items: carts?.products?.map((item: any) => ({
					item_id: item?.p_id,
					quantity: item?.qty,
					item_name: item?.p_name
				})),
				value: data?.po_payable_total,
				contents: carts?.products,
				address: data?.user_default_location
			});
			getCart();
		} else {
			setCarts({ ...data, responseMessage: message });
		}

		setOrderData({ ...data, responseMessage: message });
		setOrderDataGlobal(data);
	} catch (error) {
		console.error('Order creation failed:', error);
		alert.error({
			title: 'Error',
			message: 'An unexpected error occurred. Please try again later.',
			confirmText: 'OK',
			onConfirm: () => setShowCheckoutModal(false)
		});
	} finally {
		setOrderLoading(false);
		// getCart();
	}
};
