import React from 'react';
import ContentLoader from 'react-content-loader';

const ScheduleSkeletonLoader = ({ props }) => {
	return (
		<ContentLoader
			speed={2}
			width={'130px'}
			height={'35px'}
			// viewBox='0 0 800 200'
			backgroundColor='#e8e8e8'
			foregroundColor='#fcfcfc'
			{...props}>
			<rect x='0' y='0' rx='3' ry='3' width='130' height='35' />
			{/* <rect x='1' y='46' rx='0' ry='0' width='468' height='10' /> */}
		</ContentLoader>
	);
};

export default ScheduleSkeletonLoader;
