import Card from '@/components/atoms/Card';

const OrderSummaryCheckOut = ({ data }: any) => {
	return (
		<Card bordered={false} bodyStyle={{}} title='' size='small'>
			<div className='mx-5' style={{ borderBottom: '1px solid #F1F2F4' }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%'
					}}>
					<p style={{ margin: 0 }}>Subtotal (MRP)</p>
					<p style={{ margin: 0, textAlign: 'right' }}>৳{data?.po_subtotal}</p>
				</div>

				{data?.po_general_discount_amount > 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '5px',
							width: '100%'
						}}>
						<p style={{ margin: 0 }}>Discount Applied</p>
						<p style={{ margin: 0, color: '#FD6A6A', textAlign: 'right' }}>
							-৳{data?.po_general_discount_amount}
						</p>
					</div>
				)}
				{data?.po_user_cash_used > 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							marginTop: '5px'
						}}>
						<p style={{ margin: 0 }}>Arogga Cash Applied</p>
						<p style={{ margin: 0, textAlign: 'right', color: '#FD6A6A' }}>-৳{data?.po_user_cash_used}</p>
					</div>
				)}

				{data?.po_coupon_discount_amount > 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '5px',
							width: '100%'
						}}>
						<p style={{ margin: 0 }}>Coupon Applied ({data?.po_discount_code})</p>
						<p style={{ margin: 0, color: '#FD6A6A', textAlign: 'right' }}>-৳{data?.po_coupon_discount_amount}</p>
					</div>
				)}
				{/* 
				{data?.po_cash_back > 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '5px',
							width: '100%'
						}}>
						<p style={{ margin: 0 }}>Offer</p>
						<p style={{ margin: 0, color: '#FD6A6A', textAlign: 'right' }}>-৳{data?.po_cash_back}</p>
					</div>
				)} */}

				{data?.po_rounding_amount > 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '5px',
							width: '100%'
						}}>
						<p style={{ margin: 0 }}>Rounding Off</p>
						<p style={{ margin: 0, color: '#FD6A6A', textAlign: 'right' }}>-৳{data?.po_rounding_amount}</p>
					</div>
				)}

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginTop: '5px',
						marginBottom: '10px',
						width: '100%'
					}}>
					<p style={{ margin: 0 }}>Regular Delivery</p>
					<p style={{ margin: 0, textAlign: 'right' }}>
						{' '}
						{data?.po_delivery_fee === 0 ? (
							<span style={{ color: '#0E7673' }}>Free</span>
						) : (
							<span>+৳{data?.po_delivery_fee}</span>
						)}
					</p>
				</div>
			</div>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '10px',
						width: '100%',
						marginTop: '10px'
					}}>
					<p className='mx-5' style={{ fontSize: '16px', fontWeight: 600 }}>
						Amount Payable:
					</p>
					<p className='mx-5' style={{ fontSize: '16px', fontWeight: 600, textAlign: 'right', color: '#0E7673' }}>
						৳{data?.po_payable_total}
					</p>
				</div>
			</div>
		</Card>
	);
};

export default OrderSummaryCheckOut;
