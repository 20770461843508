import styles from './Chip.module.css';
//variant = primary,blue,orange,yellow,error

const Chip = ({ size, text, variant, icon, onClick }) => {
	return (
		<div className={`${styles.chip} ${variant ? styles[variant] : ''} flex`} onClick={onClick && onClick}>
			<p className={`${size ? styles[size] : 'ltbody_md__regular'}`}>{text}</p>
			{icon && <p className={`ml5`}>{icon}</p>}
		</div>
	);
};

export default Chip;
