'use client';

import Button from '@labtest/presentation/atoms/Button';
import Line from '@labtest/presentation/atoms/Line';
import lottie from 'lottie-web';
import animated_graphic from './data.json';

import { Alarm, AutoGraph, MarkEmailRead, Science } from '@labServices/utils/svg';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import styles from './OrderSuccesssModal.module.css';

const OrderSuccessModal = ({ data, title, message, closeOrderSuccessModal, closeCheckoutModal }) => {
	const router = useRouter();

	const handlerViewOrderDetails = () => {
		closeOrderSuccessModal();
		// closeCheckoutModal();
		router.push(`/account/labTest/orders/labtest-orders/${data?.id}`);
	};
	const handlerCreateNewOrder = () => {
		closeOrderSuccessModal();
		// closeCheckoutModal();
		router.push('/lab-test/tests');
	};

	useEffect(() => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			const animation = lottie.loadAnimation({
				loop: true,
				renderer: 'svg',
				animationData: animated_graphic,
				container: document.getElementById('animated_graphic')
			});
			return () => animation.destroy();
		}
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.icon_container_wrap}>
				<div className={styles['order-success-graphics']} id='animated_graphic'></div>

				<div className={styles.content_container}>
					<h1>{title}</h1>
					<p>{message}</p>
				</div>
			</div>

			<div className={styles.reasons_container_wrap}>
				<div>
					<Line />
					<div className={styles.order_details_container}>
						<>
							<div className={styles['order-success-bottom']}>
								<div className={styles['order-info']}>
									<div className={styles['order-id']}>
										<div className={styles['icon']}>
											<AutoGraph />
										</div>
										<div className={styles['order-id-info']}>
											<div>Order ID</div>
											<div>#{data?.orderNumber}</div>
										</div>
									</div>
									<div className={styles['order-item']}>
										<div className={styles['icon']}>
											<Science />
										</div>
										<div className={styles['order-item-info']}>
											<div>Order Item</div>
											{data?.packageCount > 0 && (
												<div>
													{data?.packageCount} {data?.packageCount > 1 ? 'Packages' : 'Package'} for{' '}
													{data?.items?.reduce((previous, current) => previous + current.patientCount, 0)}{' '}
													{data?.items?.reduce((previous, current) => previous + current.patientCount, 0) >
													1
														? 'Patients'
														: 'Patient'}
												</div>
											)}
											{data?.testCount > 0 && (
												<div>
													{data?.testCount} {data?.testCount > 1 ? 'Tests' : 'Test'} for{' '}
													{data?.items?.reduce((previous, current) => previous + current.patientCount, 0)}{' '}
													{data?.items?.reduce((previous, current) => previous + current.patientCount, 0) >
													1
														? 'Patients'
														: 'Patient'}
												</div>
											)}
											{data?.packageCount > 0 && data?.testCount > 0 && (
												<div>
													{data?.packageCount} {data?.packageCount > 1 ? 'Packages' : 'Package'} &{' '}
													{data?.testCount} {data?.testCount > 1 ? 'Tests' : 'Test'} for{' '}
													{data?.items?.reduce((previous, current) => previous + current.patientCount, 0)}{' '}
													{data?.items?.reduce((previous, current) => previous + current.patientCount, 0) >
													1
														? 'Patients'
														: 'Patient'}
												</div>
											)}
										</div>
									</div>
									<div className={styles['order-sample-collection']}>
										<div className={styles['icon']}>
											<Alarm />
										</div>
										<div className={styles['order-sample-collection-info']}>
											<div>Sample Collection Schedule</div>
											<div>
												{data?.formattedScheduleDate}, {data?.scheduleTimeRange?.en}
											</div>
										</div>
									</div>
									<div className={styles['order-confirmation']}>
										<div className={styles['icon']}>
											<MarkEmailRead />
										</div>
										<div className={styles['order-confirmation-info']}>
											<div>Confirmation will be sent to your Arogga App, SMS</div>
											<div>Check your Arogga App or Phone SMS</div>
										</div>
									</div>
								</div>
							</div>
						</>
					</div>
				</div>
			</div>

			<div className={`${styles.button_container} ${styles.button_container_orderCancel}`}>
				<>
					<Button fullWidth variant={`secondary`} onClick={() => handlerViewOrderDetails()}>
						View order details
					</Button>
					<Button fullWidth color='primary' onClick={() => handlerCreateNewOrder()}>
						Create new order
					</Button>
				</>
			</div>
		</div>
	);
};

export default OrderSuccessModal;
