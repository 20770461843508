import React from 'react';
import styles from './BookforItem.module.css';

const BookforItem = ({ onClick, checked, children }) => {
	return (
		<div onClick={onClick} className={`${styles.paitent_num} ${checked ? styles.checked : ''} ltGreyscale900`}>
			{children}
			{checked ? <i className='fa-solid fa-circle-check' /> : ''}
		</div>
	);
};

export default BookforItem;
