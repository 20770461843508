import Label from '../Label';
import styles from './input.module.css';

const Input = (props) => {
	const { placeholder, label, required, variant, ...rest } = props;
	return (
		<div className={styles.input_wrap}>
			{label && <Label label={label} required={required} />}
			<input
				{...rest}
				className={`${styles.input} ${variant ? styles[variant] : ''}`}
				required={required}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default Input;
